import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import {
  Button,
  Carousel,
  CarouselItem,
  Backtop,
  Card,
  Form,
  FormItem,
  Input,
  Message
} from 'element-ui'
Vue.prototype.$message = Message
Vue.component(Message.name, Message)
Vue.use(Button)
  .use(Carousel)
  .use(CarouselItem)
  .use(Backtop)
  .use(Card)
  .use(Form)
  .use(FormItem)
  .use(Input)
