import Vue from 'vue'
import VueRouter from 'vue-router'
const Layout = () => import('@/layout/Layout')
const Home = () => import('@/views/Home')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Home'
  },
  {
    path: '/Home',
    component: Layout,
    children: [
      {
        path: '',
        name: 'News',
        component: Home,
        meta: {
          title: '智能体'
        }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/Home'
  }
]

const router = new VueRouter({
  routes
})

export default router
