import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    myNumber: window.sessionStorage.getItem('number'),
    token: window.sessionStorage.getItem('token'),
    userId: window.sessionStorage.getItem('userId')
  },
  mutations: {
    setNumber (state, num) {
      state.myNumber = num
    }
  },
  actions: {
    // addNumber (context, number) {
    //   context.commit('setNumber', number)
    // }
  },
  modules: {
  }
})
